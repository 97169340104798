@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Readex+Pro:wght@160..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Titillium Web";
}

html {
    scroll-behavior: smooth;
}

.tilt {
    transform-style: preserve-3d;
}

.tilt .tilt-inner {
    transform: translateZ(40px);
}

.tilt .tilt_inner_img {
    transform: translateZ(40px);
}

body {
    background: linear-gradient(#414187, white);
    background-repeat: no-repeat;
    overflow-x: hidden !important;
    width: 100% !important;

}

::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(white, #414187);
    border-radius: 2px;

}
.farabiulder_img {
    background-color: white;
}

.contact-me {
    position: fixed;
    bottom: -1px;
    left: 20px;
    z-index: 200;
    width: 10%;
    height: 5%;
    background-color: #414187;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    /* text-align: center; */
    /* padding-top: 10px; */
    display: grid;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 0.3s ease;
    font-size: 25px;
    cursor: pointer;


}

#icon-mail {
    color: white;
    margin-top: 5px;
}

.contact-me-a {
    z-index: 1;
    width: 150px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

}

#scroll-btn {
    position: absolute;
    height: 3em;
    width: 1.8em;
    border: 2px solid white;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    border-radius: 3em;
    cursor: pointer;
}

#scroll-btn::before,
#scroll-btn::after {
    content: '';
    position: absolute;
    top: 20%;
    left: 50%;
    border: 2px solid white;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border-top: transparent;
    border-left: transparent;

    animation: move-down 1.5s infinite ease-in-out;
}

#scroll-btn::after {
    top: 30%;
    animation-delay: .3s;
}

@keyframes move-down {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        top: 90%;
    }

}

.social-media {
    position: fixed !important;
    background: white;
    width: 5%;
    height: 50vh;
    top: 25%;
    display: grid;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    transform: translate(-80%);
    transition: 0.3s ease;
    z-index: 123712;
}


.social-media:hover {
    transform: translate(0);
    box-shadow: 0px 0px 50px 0px white;
}

.book_a_time_with_me {
    width: 100%;
    margin: 100px 0 50px 0;
    text-align: center;
    font-size: 30px;
}

.sosoci {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 80%;
    gap: 20px;
}
.sosoci a {
    cursor: pointer;
    margin: initial;
    padding: initial !important;
}
.sosoci a:hover {
    transform: translateX(5px);
}
.sp-widget-launcher {
    position: fixed;
    right: 30px !important;
    top: 30px !important;
    height: 50px;
}

.sosoci hr {
    border: 0.50px solid rgb(192, 192, 192);
}

.sosoci ion-icon {
    font-size: 30px;
}

#github-a {
    transition: 0.2s;

}

#github-a:hover {
    color: #333333;
}

#twitter-a {

    transition: 0.2s;
}

#twitter-a:hover {
    color: #1DA1F2;
}

#instagram-a {

    transition: 0.2s;


}

#instagram-a:hover {
    color: #bc2a8d;

}
#linkedin-a {

    transition: 0.2s;


}

#linkedin-a:hover {
    color: #0077B5;

}







.box {
    border: 1px solid black;
    background: white;
    width: 10%;
    padding: 10px;


    transform: rotate(45deg);
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px
}

/* ion-icon {
    transition: 0.3s ease;
} */
.contact-me:hover {
    height: 7%;
    box-shadow: 0px 0px 30px 0px #414187;
}

#section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

#section-1 img {
    width: 35%;
    /* height: 40vh; */
    /* border-radius: 60% 40% 40% 70% / 75% 40% 70% 40%; */
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0px 0px 50px 0px #2f2f63;
}

.texts {
    padding: 10px;
    margin-left: 30px;
}

#section-1 p {
    z-index: -1;
    color: white;
    font-size: 25px;
    /* margin-left: 30px; */


}

#section-1 h2 {
    font-size: 35px;
    z-index: -1;
    color: white;
    font-weight: initial;
}

.content-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    width: 70%;
}

.box {
    --width: 70vmin;
    --height: 70vmin;
    --depth: 70vmin;
    --hue: 30;
    --alpha: 0.8;
    z-index: -19;
    width: var(--width);
    height: var(--height);
    position: absolute;
    top: 15%;
    left: -25%;
    /* background-color: hsla(var(--hue), 50%, 50%, var(--alpha)); */
    background-color: rgba(0, 0, 0, 0.081);
    perspective: 800px;
    transform-style: preserve-3d;
    transform: rotateX(-24deg) rotateY(24deg);
    animation: 200s line_transfrom linear infinite;
}

.box__face {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.081);
}

.box__face--back,
.box__face--front {
    width: var(--width);
    height: var(--height);

    --hue: 220;
    --alpha: 0.6;
    --coeff: -0.5;
    transform: translate3d(0, 0, calc(var(--depth) * var(--coeff)));
    animation: 200s line_transfrom linear infinite;
}

.box__face--front {

    --coeff: 0.5;
}

.box__face--left,
.box__face--right {
    width: var(--depth);
    height: var(--height);

    --rotate: 90deg;
    --hue: 160;
    --alpha: 0.8;

    transform: rotateY(var(--rotate)), translate3d(0, 0, calc(var(--width) * 0.5));
    animation: 20s line_transfrom linear infinite;
}

.box__face--top,
.box__face--bottom {
    width: var(--width);
    height: var(--depth);
    --hue: 30;
    --alpha: 0.6;
    --rotate: 90deg;
    transform: rotateX(var(--rotate)), translate3d(0, 0, calc(var(--width) * 0.5));
}

.box__face--bottom {
    --rotate: -90deg;
}

.line {
    border: 0.5px solid rgba(255, 255, 255, 0.521);
    border-radius: 20px;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    animation: 100s line_transfrom infinite linear;
}

@keyframes line_transfrom {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.line1 {
    transform: rotate(45deg);

}

.line2 {
    transform: rotate(-45deg);
    margin-top: 220px;





}

.line3 {
    transform: rotate(60deg);
    margin-top: 260px;
    margin-left: 200px;
    animation: 70s line_transfrom infinite linear;

}

.line4 {
    transform: rotate(33deg);
    margin-top: 400px;
    margin-left: 400px;

}

.line5 {
    transform: rotate(75deg);
    margin-left: 600px;

}

.line6 {
    transform: rotate(120deg);
    margin-left: 1300px;
    margin-top: 600px;


}

.line7 {
    transform: rotate(170deg);
    margin-left: 1440px;
    margin-top: 300px;

}

.line8 {
    transform: rotate(156deg);
    margin-left: 800px;
    margin-top: 250px;

}

.line9 {
    transform: rotate(47deg);
    margin-left: 600px;
    margin-top: 700px;
    animation: 40s line_transfrom infinite linear;
}

.line10 {
    transform: rotate(66deg);
    margin-left: 1600px;
    margin-top: 450px;
    animation: 55s line_transfrom infinite linear;
}

.line11 {
    transform: rotate(20deg);
    margin-left: 470px;
    margin-top: 0px;

}

.line12 {
    transform: rotate(59deg);
    margin-left: 940px;
    margin-top: 0px;

}

.line13 {
    transform: rotate(120deg);
    margin-left: 1040px;
    margin-top: 30%;

}

.line14 {
    transform: rotate(20deg);
    margin-left: 470px;
    margin-top: -10px;


}

.project p::-webkit-scrollbar {
    background: linear-gradient(transparent, transparent);
}

.project p::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(#525292, #525292);
}

.project .desc {}

/* p'leri ortala */
.project-p {
    margin-bottom: 20px;
    min-height: 17vh !important;
    max-height: 17vh !important;
    padding: 5px;

}

.p_overflow {
    overflow-y: scroll;
}

.project-p a {
    margin: 0 !important;
    position: relative;
    color: #414187;

}

.project-p a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1.2px;
    background: #414187;
    transition: width 0.25s ease-out;
    border-radius: 40px;
}

.project-p a:hover::before {
    width: 100%;



}

#skills {
    border-bottom: 1px solid white;
    color: white;
    letter-spacing: 2px;
    padding-left: 5px;

    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.cart {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-wrap: wrap;


}

.container {
    background: rgba(11, 11, 11, 0.458);
    border-radius: 12px;
    width: 320px;
    height: 190px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;


}

.container:hover {
    box-shadow: 0px 0px 30px 0px #414187;
}

.circular-progress {
    position: relative;
    width: 150px;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    background: conic-gradient(#264de4 3.6deg, transparent 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: white;

}

.circular-progress::before {
    content: "";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background-color: #000000;

}

.progress-value {
    font-size: 20px;
    position: relative;
}

.text {
    font-size: 20px;
    color: #cdcdcd;
}

.circular-progress-2 {
    position: relative;
    width: 150px;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    background: conic-gradient(#61DBFB 3.6deg, transparent 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: white;
}

.circular-progress-2::before {
    content: "";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background-color: #000000;
}

.progress-value-2 {
    font-size: 20px;
    position: relative;
}

.text-2 {
    font-size: 20px;
    color: #606060;
}

.circular-progress-3 {
    position: relative;
    width: 150px;
    background-color: black;
    border-radius: 50%;
    background: conic-gradient(#f7df1e 3.6deg, transparent 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: white;
}

.circular-progress-3::before {
    content: "";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background-color: #000000;
}

.progress-value-3 {
    font-size: 20px;
    position: relative;
}

.text-3 {
    font-size: 20px;
    color: #606060;
}

.circular-progress-4 {
    position: relative;
    width: 150px;
    background-color: black;
    border-radius: 50%;
    background: conic-gradient(#90B945 3.6deg, transparent 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.circular-progress-4::before {
    content: "";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background-color: #000000;
}

.progress-value-4 {
    font-size: 20px;
    position: relative;
    color: white;
}

.text-4 {
    font-size: 20px;
    color: #606060;
}

.circular-progress-5 {
    position: relative;
    width: 150px;
    background-color: black;
    border-radius: 50%;
    background: conic-gradient(#1b769c 3.6deg, transparent 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: white;
}

.circular-progress-5::before {
    content: "";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background-color: #000000;
}

.circular-progress-6 {
    position: relative;
    width: 150px;
    background-color: black;
    border-radius: 50%;
    background: conic-gradient(#08089C 3.6deg, transparent 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    color: white;
}

.circular-progress-6::before {
    content: "";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background-color: #000000;
}

.progress-value-5 {
    font-size: 20px;
    position: relative;
}

.text-5 {
    font-size: 20px;
    color: #606060;
}

.progress-value-6 {
    font-size: 20px;
    position: relative;
}

.text-6 {
    font-size: 20px;
    color: #606060;
}

#section-2 {
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 40vh;
    margin-top: 100px;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../public/best.jpeg") no-repeat fixed;
    background-size: cover;
    background-position-y: -60em;
    overflow: hidden;
    padding: 20px 20px 20px 20px;
-webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
}

.point {
    width: 2px;
    border: 1px solid white;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    display: none;
}

.point1 {
    transform: translate(-600px);
    margin-top: -100px;
}

.point2 {
    transform: translate(-500px);
    margin-top: 100px;
}

.point3 {
    transform: translate(-700px);
    margin-top: 150px;
}

.point4 {
    transform: translate(-400px);
    margin-top: -50px;
}

.point5 {
    transform: translate(400px);
    margin-top: -250px;
}

.point6 {
    margin-top: -250px;
    transform: translate(200px);

}

.point7 {
    margin-top: -210px;
    transform: translate(-200px);

}

.point8 {
    margin-top: -280px;

}

.point9 {
    margin-top: 180px;
    transform: translate(700px);

}

.point10 {
    margin-top: 60px;
    transform: translate(550px);

}

.point11 {
    margin-top: -100px;
    transform: translate(650px);

}

.point12 {
    margin-top: 250px;

}

.point13 {
    margin-top: 200px;
    transform: translate(300px);
}

.point14 {
    margin-top: 280px;
    transform: translate(-250px);
}

.bebebenn {
    width: 25%;
    z-index: 923923;
    border-radius: 10% 100% 100% 100%;
}




#section-2 div {
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
}


.sec2-line {
    border: 0.5px solid white;
    margin-left: 130px;
}

.about-me {
    height: 30vh;
    display: grid;
    align-items: center;
    justify-content: center;

}

.about-me-h2 {
    color: white;
    width: 100%;
    font-size: 300px;
    letter-spacing: 10px;
    opacity: 0.1 !important;
    position: absolute;
    left: 0;
    text-align: center;

}

#section-2 p {
    width: 93%;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 26px;




}

#section-3 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 100px;
    border-bottom: 1px solid white;
    padding: 20px;
    border-radius: 50%;
    color: white;
}

.sec3-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contact-me-in-magephe {
    border-radius: 5px;

}

.general-container {
    display: flex;
    width: 95%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px 10px;
}

.general-container .inner_general_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.project {
    width: 49%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* flex-direction: column; */
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0px 20px 40px 0px rgba(26, 25, 25, 0.252);
    background-color: rgba(255, 255, 255, 0.4);
    align-items: center;
    justify-content: space-between;
    min-height: 30vh;


}

.section-4-footer {
    display: flex;
    border: 1px solid black;
    align-items: flex-end;

}

.shopify {
    width: 11%;
    margin-left: 40px;
    height: 5.7vh;
    border-radius: 10px;
    z-index: -1;
}

.section-5-footer {
    display: flex;
    border: 1px solid black;
    align-items: flex-end;

}

.mobirise {
    width: 10px;
    margin-left: 40px;
    /* height: 5.7vh; */
    /* border-radius: 10px; */
    z-index: -1;
    border: 1px solid black;
}

hr {
    width: 85%;
    margin-left: auto;
    border: 0.5px solid white;
    margin-right: auto;
}

.project img {
    width: 20%;
    margin-right: 20px;
    border-radius: 500px;
}

.long-container-for-project {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
}

.lc-project {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px;
    display: grid;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.4);
    align-items: center;
    justify-content: center;

}

.lc-project-div img {
    width: 11%;

    margin-right: 20px;
    border-radius: 500px;
}

.lc-project-div {
    display: grid;
    align-items: center;
}

.visit-site {

    display: flex;
    justify-content: center;
    padding: 10px 50px;
    background-color: #414187;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #414187;
    color: white;
    transition: 0.3s ease;

}

.visit-site-off {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 10px 50px;
    background-color: #6c6c6c;
    cursor: not-allowed;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #6c6c6c;
    color: white;
    transition: 0.3s ease;

}

.visit-site:hover {
    box-shadow: 0px 0px 30px 0px #414187;
}

.visit-site ion-icon {
    font-size: 20px;
}

.visit-site-off ion-icon {
    font-size: 20px;
}

.project-h2 {
    margin-bottom: 10px;
}

h1 {
    font-size: 30px;

}

p {
    font-size: 20px;


}

.a {
    text-decoration: none;
    color: #414187;

}

a {
    display: inline-block;
    /* padding-bottom: 0.25rem; */
    position: relative;
    color: black;
    text-decoration: none;
}

/* a::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background: #414187;
    transition: width 0.25s ease-out;
}
a:hover:before {
    width: 100%;
   

   
} */
.bs-uberdacung-img {
    width: 20% !important;
    height: 65%;
}

.my-project div {
    width: 20%;
    display: inline-block;
    height: 20px;
}

.demo_project {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    display: grid;
    align-items: center;
    margin-top: 100px;
}

.demo_project .display_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.demo_project_h2_first {
    text-align: left;
    font-weight: 400;
    border-bottom: 3px solid black;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.demo_project .project_div_demo h2 {
    text-align: center;
    font-weight: 200;

}

.demo_project .desc button {
    margin: 0 auto;
}

.inner_demo_project {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../public/aaaaaaaaaaaaa-removebg-preview.png");
    background-size: 200px;
    /* background-repeat: no-repeat; */
    width: 50%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px;
    display: grid;
    padding: 25px 0;
    border-radius: 8px;
    background-attachment: fixed;

}

.inner_demo_project h2 {
    font-weight: 300;
}

.inner_demo_project img {
    width: 20%;

}

.inner_demo_project_2 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../public/dondurma (1).png");
    background-size: 100px;
    background-attachment: fixed;
    /* background-repeat: no-repeat; */
    width: 50%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px;
    display: grid;
    padding: 25px 0;
    border-radius: 8px;

}

.inner_demo_project_2 h2 {
    font-weight: 300;
}

.inner_demo_project_2 img {
    width: 20%;

}

.inner_demo_project_3 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../public/Screenshot_2.png");
    background-size: 100px;
    background-attachment: fixed;
    /* background-repeat: no-repeat; */
    width: 50%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px;
    display: grid;
    padding: 25px 0;
    border-radius: 8px;

}

.inner_demo_project_3 h2 {
    font-weight: 300;
}

.inner_demo_project_3 img {
    width: 20%;

}

.inner_demo_project_4 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../public/Screenshot_2.png");
    background-size: 200px;
    background-attachment: fixed;
    /* background-repeat: no-repeat; */
    width: 50%;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin: 20px 10px;
    display: grid;
    padding: 25px 0;
    border-radius: 8px;

}

.inner_demo_project_4 h2 {
    font-weight: 300;
}

.inner_demo_project_4 img {
    width: 20%;

}

.project_div_demo {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tools {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    color: black;
    margin-top: 50px;


}

.tools h1 {
    margin-bottom: 20px;
    text-align: left;
    border-bottom: 1px solid white;
    color: white;
}

.tools .chapter-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.tools .chapter-1 p {
    padding: 10px 15px;
    width: 250px;
    height: 70px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.33);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.tools .chapter-1 img {
    width: 60px;
    border-radius: 50%;
    margin-left: 10px;
    /* background-color: white; */
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
}

.tools .chapter-2 table {
    display: flex;
    justify-content: space-around;
}


.i-dont {
    text-align: center;
    margin: 30px;

}

footer {
    text-align: center;
    padding: 20px 0;
    background: black;
    color: white;
    overflow: hidden;

}

.cedeny-a {
    border: 1px solid darkgray;
    padding: 0 10px;
    border-radius: 3px;
    background: darkgray;
    color: black;
}

#footer_magephe {

    cursor: pointer;
}

@media (max-width: 400px) {
    .bmec-btn {
        display: none;
    }

    .box {
        display: none;
    }

    .about-me-h2 {
        font-size: 100px !important;
    }

    #scroll-btn {
        display: none;
    }

    .contact-me {
        width: 200px;
        height: 5%;
    }

    .fornow-h2 {

        font-size: 30px !important;
    }

    .about-me-p {
        font-size: 10px !important;
    }

    .general-container {
        display: grid;
    }

    .demo_project {
        display: none;
    }

    .general-container .inner_general_container {
        display: grid;
    }

    .project-div {
        display: grid;
        place-items: center;
    }

    .project img {
        width: 50%;
        display: grid;
        margin-bottom: 20px;
        margin-right: 0px;
    }

    #section-2 {
        background-position-y: 0em !important;
        height: auto;
    }
}

@media (max-width:500px) {
    body {
        width: 100% !important;
        background-color: white;
    }
    .line {
        display: none;
    }
    .sp-widget-launcher {
        right: 10px !important;
        top: 10px !important;
    }
    
    
    
    .box {
        display: none;
    }

    #scroll-btn {
        bottom: 7px;

    }

    .about-me-h2 {
        font-size: 98px !important;
    }

    .general-container {
        display: grid;
    }

    .project {
        width: 80%;
        margin: 30px auto;
    }

    #section-1 {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .demo_project {
        display: none;

    }

    .cart {
        display: grid;
    }

    .content-1 {
        width: 100%;
        display: grid;
        align-items: center;
        justify-content: center;
        margin-left: 20px;

    }

    .social-media {
        width: 11% !important;
        top: 25vh !important;
    }
    .social-media:hover{
        width: 12% !important;
        top: 25vh !important;
    }

    .about-me-p {
        font-size: 16px !important;
        width: 100% !important;
    }

    .project-div {
        display: grid;
        text-align: center;
    }

    .project-div .bs-uberdacung-img {
        height: 150px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .project-div .cedeny-img {
        height: 150px;
        width: 150px !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #section-1 img {
        height: 250px;
        width: 250px !important;
        margin-left: auto;
        margin-right: auto;
    }

    #section-1 p {
        font-size: 26px;
        text-align: center;
        margin-top: 20px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .contact-me {
        width: 140px !important;
        height: 5vh;
    }

    .line {
    }

    #section-2 {
        display: grid;
        background-position-y: 0 !important;
        background-position-x: -50px;
        background-size: cover;

    }

    #section-2 div {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 100vh !important;
        padding: 10px;

    }

    .sec2-line {
        display: none;
    }

    .sec4-div {
        display: grid;
        align-items: center;
        justify-content: center;
    }

    #section-4 img {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .project-h2 {
        text-align: center;
    }

    #section-4 {
        text-align: justify;
    }

    .visit-site,
    .visit-site-off {
        margin-left: auto;
    }

    .visit-site-off {
        margin-right: auto;
    }

    .sec5-div {
        display: grid;
    }

    #section-5 img {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .sec5-div {
        text-align: justify;
    }

    footer {
        background-color: black;
        color: white;
        text-align: center;
        display: grid;
        align-items: center;
        justify-content: center;
        height: 13vh;

    }

    footer p a {
        /* display: none; */
    }

    .general-container .inner_general_container {
        display: grid;
    }

    .project-div {
        display: grid;
        place-items: center;
    }

    .project img {
        width: 50%;
        display: grid;
        margin-bottom: 20px;
        margin-right: 0px;
    }


}


/* @media(max-width: 400px){
    #section-1 .content-1 img {
      width: 72vw;;
    }
}    
@media(max-width: 450px){
    #section-1 .content-1 img {
      width: 76vw;;
    }
}     */
@media (max-width: 984px) {
    .visit-site {
        margin-top: 20px !important;

    }

    .project {
        width: 90%;
    }

    .about-me-h2 {
        font-size: 150px !important;
    }

    #section-2 {
        background-position-y: -10em !important;
    }

    #section-1 img {
        width: 60%;
    }

    .texts {
        text-align: center;
        width: 100%;
    }

    .box {
        top: 0;
    }

    .visit-site,
    .visit-site-off {
        margin-left: auto;
    }

    .visit-site-off {
        margin-right: auto;
    }
}

@media (max-width: 1024px) {
    .miramar_p {
        overflow-y: scroll;
    }

    .chapter-1 {
        display: flex;
        flex-wrap: wrap;
    }

    .cart {
        display: flex;
        flex-wrap: wrap;
    }

    #section-2 {
        background-position: 0;
    }
}

.visit-site ion-icon {
    margin-left: 10px;
}

@media (max-width: 894px) {
    .koso_p {
        overflow-y: scroll;
    }
}

@media (max-width: 886px) {
    .bs_p {
        overflow-y: scroll;
    }
}

@media (max-width: 793px) {

    .visit-site,
    .visit-site-off {
        display: flex;
        place-items: center;
    }

    .inner_general_container {}

    .bs-uberdacung-img {
        width: 100px !important;
        height: 100px !important;

    }

}

@media (max-width: 1100px) {
    .visit-site {
        margin-top: 10px !important;
    }

    .chapter-1 p {

        margin: 10px;
    }

    .project img {
        width: 100px !important;
    }

    .bs-uberdacung-img {
        height: 100px;
    }

}

@media (max-width:1000px) {
    #section-2 {
        background-position: 0;
    }

    #social-media {
        top: 0;
        width: 8%;
    }

    .content-1 {
        width: 80%;
    }

    #section-2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .bs-uberdacung-img {
        width: 100px;
        height: 100px;
    }

    .contact-me {
        width: 20%;
    }

    .texts {}

    .cart {
        display: flex;
        flex-wrap: wrap;
    }

    .chapter-1 {
        display: flex;
        flex-wrap: wrap;
    }



}

@media (max-width: 820px) {
    .content-1 {
        width: 90%;
        justify-content: center;
        align-items: center;
        display: grid;
    }

    .content-1 img {
        width: 40%;
        margin-left: auto;
        margin-right: auto;


    }

    .about-me-h2 {
        font-size: 98px !important;
    }

    #section-2 {
        background-position-y: 0 !important;
    }

    .texts {
        margin-left: auto !important;
        width: 100%;
        text-align: center;
    }

    .about-me-p {}

    .chapter-1 {
        flex-wrap: wrap;
    }

    .chapter-1 p {

        margin: 10px;
    }

    .bs-uberdacung-img {
        height: 55%;
    }
}

@media (max-width: 1105px) {
    #section-2 {
        background-position-y: -30em;

    }

    .about-me-h2 {
        font-size: 200px;
    }

    .cart {
        flex-wrap: wrap;
    }
}